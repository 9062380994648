import styled from 'styled-components'

export const StyledForm = styled.form`
  margin: 0px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`

export const MoreButton = styled.a`
  cursor: pointer;
  padding-left: 7px;

  font-weight: 400;
  font-size: 10px;
  text-decoration: none;

  color: #3ca243;
  background-color: transparent;
  border: none;
`
