import React from 'react'

import {
  generateSlides,
  SlidesDataType,
} from 'components/modules/GenerateSlides'

import { H1Light, TextBody1 } from 'components/atoms/Typography'
import {
  Wrapper,
  StyledContainer,
  TextWrapper,
} from './LocationAdvantages.styles'

type Props = {
  heading: string
  text: string
  tiles: SlidesDataType
}

export const LocationAdvantages: React.FC<Props> = ({
  heading,
  text,
  tiles,
}) => {
  return (
    <Wrapper>
      <StyledContainer margin={[0, 'auto']} padding={[0, 40]}>
        <TextWrapper>
          <H1Light
            dangerouslySetInnerHTML={{ __html: heading }}
            align="center"
            as="h2"
          />
          <TextBody1
            dangerouslySetInnerHTML={{ __html: text }}
            align="center"
          />
        </TextWrapper>
        <>{generateSlides(tiles)}</>
      </StyledContainer>
    </Wrapper>
  )
}
