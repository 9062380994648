import React, { useState, useRef } from 'react'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api'
import mapStyles from 'assets/misc/mapStyles'

import useBreakpoint from 'hooks/useBreakpoint'

import { Button } from 'components/atoms/Button'
import { TextBody2, TextButton2 } from 'components/atoms/Typography'

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-location-green.svg'
import { ReactComponent as BikeIcon } from 'assets/icons/bike.svg'
import { ReactComponent as CarIcon } from 'assets/icons/car.svg'
import { ReactComponent as PublicTransportIcon } from 'assets/icons/public-transport.svg'

import {
  DirectionsAndMapWrapper,
  DirectionsWrapper,
  Label,
  Input,
  ArrowIconWrapper,
  TravelModeButtonsWrapper,
  TravelModeButton,
  ButtonTooltip,
  MapWrapper,
} from './Directions.styles'

type Props = {
  locationCoords: {
    lat: number
    lng: number
  }
}

export const MapWithDirections: React.FC<Props> = ({ locationCoords }) => {
  const { lg } = useBreakpoint()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCjuSiBajzm2xFw5L5ag525NhtbITpO94g',
  })

  const [currentTravelMode, setCurrentTravelMode] = useState<
    'BICYCLING' | 'DRIVING' | 'TRANSIT'
  >('BICYCLING')

  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null)

  const originRef = useRef<HTMLInputElement | null>(null)

  async function calculateRoute() {
    if (originRef?.current?.value?.trim() === '') {
      return
    }
    const directionsService = new google.maps.DirectionsService()
    await directionsService.route(
      {
        origin: originRef?.current?.value!,
        destination: `${locationCoords.lat}, ${locationCoords.lng}`,
        travelMode: currentTravelMode as google.maps.TravelMode,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result)
        } else {
          console.error('error fetching directions', result, status)
        }
      }
    )
  }

  const renderMap = () => {
    return (
      <DirectionsAndMapWrapper>
        <DirectionsWrapper>
          <Label>
            <TextBody2>Punkt startowy</TextBody2>
            <Input
              type="text"
              placeholder="Wpisz punkt startowy"
              ref={originRef}
            />
          </Label>
          <ArrowIconWrapper>
            <ArrowIcon />
          </ArrowIconWrapper>
          <Label>
            <TextBody2 color="gray10">Punkt docelowy</TextBody2>
            <Input type="text" placeholder="Miasteczko Jutrzenki" disabled />
          </Label>
          <TravelModeButtonsWrapper>
            <TravelModeButton
              onClick={() =>
                setCurrentTravelMode(google.maps.TravelMode.BICYCLING)
              }
              active={currentTravelMode === 'BICYCLING'}
            >
              <BikeIcon />
              <ButtonTooltip>
                <TextBody2 color="white">Rowerem</TextBody2>
              </ButtonTooltip>
            </TravelModeButton>
            <TravelModeButton
              onClick={() =>
                setCurrentTravelMode(google.maps.TravelMode.DRIVING)
              }
              active={currentTravelMode === 'DRIVING'}
            >
              <CarIcon />
              <ButtonTooltip>
                <TextBody2 color="white">Samochodem</TextBody2>
              </ButtonTooltip>
            </TravelModeButton>
            <TravelModeButton
              onClick={() =>
                setCurrentTravelMode(google.maps.TravelMode.TRANSIT)
              }
              active={currentTravelMode === 'TRANSIT'}
            >
              <PublicTransportIcon />
              <ButtonTooltip>
                <TextBody2 align="center" color="white">
                  Transportem publicznym
                </TextBody2>
              </ButtonTooltip>
            </TravelModeButton>
          </TravelModeButtonsWrapper>
          <Button onClick={() => calculateRoute()}>
            <TextButton2 color="white">Szukaj</TextButton2>
          </Button>
        </DirectionsWrapper>
        <MapWrapper>
          <GoogleMap
            options={{ styles: mapStyles }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={locationCoords}
            zoom={14}
          >
            <Marker
              position={locationCoords}
              icon={{
                url: '/miasteczko-jutrzenki-pin.svg',
                scaledSize: lg
                  ? new window.google.maps.Size(128, 128)
                  : new window.google.maps.Size(80, 80),
              }}
              zIndex={5}
              title="Biuro sprzedaży"
            />

            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </MapWrapper>
      </DirectionsAndMapWrapper>
    )
  }

  if (loadError) {
    return <div>Przepraszamy, mapa nie mogła zostać teraz załadowana.</div>
  }

  return isLoaded ? renderMap() : <div>Wczytywanie mapy...</div>
}
