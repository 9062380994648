import React from 'react'

import { Text } from 'components/atoms/Typography'
import { ContactForm } from './ContactFrom/ContactForm'
import {
  FormResponseContainer,
  FromContainer,
  ResponseHeader,
  StyledH3Bold,
} from './Form.styles'

type Props = {
  withDecoration?: boolean
  formTitle: string
  initialFormMessage?: string
}

export const FormItem: React.FC<Props> = ({
  withDecoration,
  formTitle,
  initialFormMessage,
}) => {
  const [formInfo, setFormInfo] = React.useState({
    text: '',
    error: false,
  })

  return (
    <FromContainer withDecoration={withDecoration}>
      {formInfo.text === '' ? (
        <>
          <StyledH3Bold
            align="left"
            dangerouslySetInnerHTML={{ __html: formTitle }}
          />
          <ContactForm
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            initialFormMessage={initialFormMessage}
          />
        </>
      ) : (
        <FormResponseContainer>
          <ResponseHeader isError={formInfo.error}>
            {formInfo.error ? 'Error' : `Dziękujemy\nza przesłanie\nformularza`}
          </ResponseHeader>
          <Text fontWeight={300} fontSize={14} fontFamily="roboto">
            {formInfo.text}
          </Text>
        </FormResponseContainer>
      )}
    </FromContainer>
  )
}
