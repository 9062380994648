import React from 'react'

import { LazyImage } from 'components/atoms/Image'
import { H1Light, H4Bold, TextBody1 } from 'components/atoms/Typography'
import { ReactComponent as CheckIcon } from 'assets/icons/check-green.svg'
import { ImageType } from 'types/image'

import { ReactComponent as LocationDesktop } from 'assets/icons/location-desktop.svg'
import { ReactComponent as LocationMobile } from 'assets/icons/location-mobile.svg'

import {
  Wrapper,
  StyledContainer,
  HeadingWrapper,
  MainWrapper,
  PlacesWrapper,
  PlaceWrapper,
  IconWrapper,
  ImageWrapper,
  DesktopDecorWrapper,
  MobileDecorWrapper,
} from './NearbyPlaces.styles'

type Props = {
  heading: string
  walkingPlacesHeading: string
  walkingPlaces: string[]
  drivingPlacesHeading: string
  drivingPlaces: string[]
  image: ImageType
}

export const NearbyPlaces: React.FC<Props> = ({
  heading,
  walkingPlacesHeading,
  walkingPlaces,
  drivingPlacesHeading,
  drivingPlaces,
  image,
}) => {
  return (
    <Wrapper>
      <StyledContainer margin={[0, 'auto']} padding={[0, 40]}>
        <HeadingWrapper>
          <H1Light
            as="h3"
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
          <DesktopDecorWrapper>
            <LocationDesktop />
          </DesktopDecorWrapper>
          <MobileDecorWrapper>
            <LocationMobile />
          </MobileDecorWrapper>
        </HeadingWrapper>
        <MainWrapper>
          <PlacesWrapper>
            <H4Bold
              dangerouslySetInnerHTML={{ __html: walkingPlacesHeading }}
            />
            {walkingPlaces?.map((place) => (
              <PlaceWrapper>
                <IconWrapper>
                  <CheckIcon />
                </IconWrapper>
                <TextBody1
                  dangerouslySetInnerHTML={{
                    __html: place,
                  }}
                />
              </PlaceWrapper>
            ))}
          </PlacesWrapper>
          <ImageWrapper>
            <LazyImage
              src={image.src}
              alt={image.alt!}
              fill
              objectFit="cover"
            />
          </ImageWrapper>
          <PlacesWrapper>
            <H4Bold
              dangerouslySetInnerHTML={{ __html: drivingPlacesHeading }}
            />
            {drivingPlaces?.map((place) => (
              <PlaceWrapper>
                <IconWrapper>
                  <CheckIcon />
                </IconWrapper>
                <TextBody1
                  dangerouslySetInnerHTML={{
                    __html: place,
                  }}
                />
              </PlaceWrapper>
            ))}
          </PlacesWrapper>
        </MainWrapper>
      </StyledContainer>
    </Wrapper>
  )
}
