import React from 'react'
import styled, { css } from 'styled-components'

import { StaticImage } from 'gatsby-plugin-image'
import media from 'styles/media'

type PositionHeight = 'top' | 'bottom'
type PositionWide = 'left' | 'right'
type Size = 'small' | 'medium' | 'big'

type MapContainerProps = {
  children: React.ReactNode
  position: [PositionHeight, PositionWide]
  size: Size
  hidden?: boolean
}

type MapWraperprops = {
  positionTop: boolean
  positionLeft: boolean
}

const MapContainerWrapper = styled.div<{ hidden: boolean }>`
  position: relative;
`

const MapWraper = styled.div<MapWraperprops>`
  width: fit-content;
  height: fit-content;

  position: absolute;

  z-index: -1;

  transform-origin: center center;

  ${({ positionTop, positionLeft }) => {
    if (positionTop && positionLeft) {
      return css`
        top: 0px;
        left: 0px;
        transform: translate(-50%, -50%);
      `
    }
    if (positionTop && !positionLeft) {
      return css`
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
      `
    }
    if (!positionTop && positionLeft) {
      return css`
        bottom: 0px;
        left: 0px;
        transform: translate(-50%, 50%);
      `
    }
    if (!positionTop && !positionLeft) {
      return css`
        bottom: 0px;
        right: 0px;
        transform: translate(50%, 50%);
      `
    }
  }}

  ${media.lg.max} {
    display: none;
  }
`

const MapContainer: React.FC<MapContainerProps> = ({
  children,
  position,
  size,
  hidden,
}) => {
  return (
    <MapContainerWrapper hidden={!!hidden}>
      {children}
      <MapWraper
        positionTop={position[0] === 'top'}
        positionLeft={position[1] === 'left'}
      >
        {size === 'small' && (
          <StaticImage src="../../../assets/images/map3.png" alt="Mapa w tle" />
        )}
        {size === 'medium' && (
          <StaticImage src="../../../assets/images/map2.png" alt="Mapa w tle" />
        )}
        {size === 'big' && (
          <StaticImage src="../../../assets/images/map1.png" alt="Mapa w tle" />
        )}
      </MapWraper>
    </MapContainerWrapper>
  )
}

export default MapContainer
