import React from 'react'
import { ImageInner, ImageWrapper } from './Image.style'

type ImageProps = {
  src: string
  alt: string
  width?: number
  height?: number
  objectFit?: React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']
  className?: string
  loading?: 'eager' | 'lazy'
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  objectFit,
  objectPosition,
  className,
  loading,
}) => {
  return (
    <ImageWrapper width={width} height={height} className={className}>
      <ImageInner
        src={src}
        alt={alt}
        objectFit={objectFit}
        objectPosition={objectPosition}
        width={width}
        height={height}
        loading={loading || 'lazy'}
      />
    </ImageWrapper>
  )
}
