import styled from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'

export const StyledH1Light = styled(H1Light)`
  ${media.xs.max} {
    text-align: left;

    margin-left: 24px;
    margin-right: auto;
    padding-right: 24px;
  }
`

export const ContactContainer = styled.div`
  padding: 100px 60px 120px;
  max-width: 1256px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  ${media.xl.max} {
    padding-left: 30px;
    padding-right: 30px;
  }
  ${media.lg.max} {
    padding: 30px;
  }
  ${media.sm.max} {
    padding: 32px 0px;
    padding-bottom: 0px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;

  ${media.md.max} {
    width: 100%;

    min-height: 0px;
  }

  &::before {
    content: '';
    width: 86px;
    height: 86px;

    position: absolute;
    bottom: -16px;
    left: -16px;

    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.primary};
    ${media.sm.max} {
      display: none;
    }
  }
`

export const ContactContent = styled.div`
  display: flex;
  justify-content: center;

  ${media.lg.max} {
    flex-direction: column-reverse;
  }
`
