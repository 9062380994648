import styled, { css } from 'styled-components'
import media from 'styles/media'

import { H3Bold, TextBody1 } from 'components/atoms/Typography'

const SlideItemStyle = css`
  width: 378px;
  height: 378px;

  padding: 32px;
  box-sizing: border-box;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  background-color: ${({ theme }) => theme.colors.primary};
`

export const StyledH3Bold = styled(H3Bold)`
  ${media.sm.max} {
    font-size: 24px;
    line-height: 130%;
  }
`

export const StyledTextBody1 = styled(TextBody1)`
  ${media.sm.max} {
    font-size: 14px;
    line-height: 150%;
  }
`

export const ImageWraper = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const SlidesContainer = styled.div`
  width: 100%;
  max-width: 1134px;

  flex: 1;

  display: flex;
  flex-direction: column;
  margin: 0px auto;

  ${media.md.max} {
    gap: 24px;
  }
`

export const SlideItemContainer = styled.div`
  ${SlideItemStyle}

  ${media.md.max} {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;

    flex-direction: column;
  }
`

export const SlideItemTextContainer = styled.div`
  ${SlideItemStyle}

  ${media.md.max} {
    width: 100%;
    height: fit-content;

    flex-direction: column;
  }
  ${media.sm.max} {
    padding: 24px;
  }
`

export const SlideItemWraper = styled.div<{
  positonRight: boolean
  reversed: boolean
}>`
  width: 100%;
  display: flex;
  gap: 0px;
  flex: 1;

  ${({ positonRight }) =>
    positonRight &&
    css`
      justify-content: flex-end;
    `}

  ${({ reversed }) =>
    reversed &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}

  ${media.md.max} {
    justify-content: flex-start;
    max-width: 520px;

    flex-direction: column;
    margin: 0px auto;
  }
`
