import styled from 'styled-components'
import media from 'styles/media'
import { Container } from 'components/atoms/Container'
import { H1Light, TextBody1 } from 'components/atoms/Typography'

export const Wrapper = styled.div`
  padding: 100px 0;

  ${media.lg.max} {
    padding: 48px 0;
  }
`

export const StyledContainer = styled(Container)`
  max-width: 1440px;

  ${media.sm.max} {
    padding: 0 24px;
  }
`

export const TextWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 48px;

  ${H1Light} {
    margin-bottom: 12px;
  }

  br {
    display: none;
  }

  ${media.sm.max} {
    margin-bottom: 32px;

    ${H1Light}, ${TextBody1} {
      text-align: left;
    }

    br {
      display: block;
    }
  }
`
