import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import { Formik, FormikHelpers, FormikProps } from 'formik'
import { z } from 'zod'
import validator from 'validator'
import { toFormikValidate } from 'zod-formik-adapter'

import emitGTMEvent from 'utils/emitGTMEvent'

import { CheckBox } from 'components/atoms/CheckBox'
import { Input } from 'components/atoms/Input'
import { Button, ButtonLine } from 'components/atoms/Button'
import { TextBodySmall } from 'components/atoms/Typography'
import { StyledTextBodySmall } from 'components/atoms/CheckBox/checkBox.styles'
import {
  CheckBoxContainer,
  InputsContainer,
  StyledForm,
} from './ContactFrom.styles'

type FormValues = {
  name: string
  email: string
  phone: string
  message: string
  emailOffers: boolean
  phoneOffers: boolean
}

type ContactFormProps = {
  formInfo: {
    text: string
    error: boolean
  }
  setFormInfo: React.Dispatch<
    React.SetStateAction<{
      text: string
      error: boolean
    }>
  >
  initialFormMessage?: string
}

const schema = z.object({
  name: z.string().nonempty('Wymagane'),
  email: z.string().email('Nieprawidłowy adress E-mail').nonempty('Wymagane'),
  phone: z.string(),
  message: z.string().nonempty('Wymagane'),
  emailOffers: z
    .boolean()
    .refine((value) => value === true, { message: 'Wymagane' }),
  phoneOffers: z.boolean(),
})

declare const window: Window & { previousPath: string }

export const ContactForm: React.FC<ContactFormProps> = ({
  formInfo,
  setFormInfo,
  initialFormMessage,
}) => {
  const ref = useRef<FormikProps<FormValues>>(null)

  useEffect(() => {
    const form = ref.current
    if (form && initialFormMessage) {
      form.setFieldValue('message', initialFormMessage)
    }
  }, [])

  const initialValues: FormValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    emailOffers: false,
    phoneOffers: false,
  }
  const informations =
    'Informujemy, że w przypadku wyrażenia zgody w jednym lub w kilkuwyżej wymienionych celach, przysługuje Pani/Panu prawo do cofnięcia <Space> zgody w dowolnym momencie, bez wpływu na zgodność zprawem przetwarzania, którego dokonano na podstawie zgody przedjej cofnięciem.'

  // TODO: Create a custom hook for expanding text
  const [expandedInfo, setExpandedInfo] = React.useState(false)

  const handleToggleExpandInfo = () => {
    setExpandedInfo(!expandedInfo)
  }

  const renderInformation = () => {
    const spaceIndex = informations.indexOf('<Space>')

    if (spaceIndex !== -1 && !expandedInfo) {
      const shortenedInformation = `${informations.substring(0, spaceIndex)}...`
      return (
        <>
          <span
            style={{ color: '#242424' }}
            dangerouslySetInnerHTML={{ __html: shortenedInformation }}
          />
          <StyledTextBodySmall
            as="span"
            color="primary"
            onClick={handleToggleExpandInfo}
          >
            Więcej
          </StyledTextBodySmall>
        </>
      )
    }
    return (
      <>
        <span
          style={{ color: '#242424' }}
          dangerouslySetInnerHTML={{ __html: informations }}
        />
        {expandedInfo && (
          <StyledTextBodySmall
            as="span"
            color="primary"
            onClick={handleToggleExpandInfo}
          >
            {' '}
            Pokaż mniej
          </StyledTextBodySmall>
        )}
      </>
    )
  }

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validate={toFormikValidate(schema)}
      onSubmit={(
        values,
        { setSubmitting, resetForm }: FormikHelpers<FormValues>
      ) => {
        if (formInfo.text && !formInfo.error) return
        ;(async () => {
          try {
            setSubmitting(true)

            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('email', values.email)
            formData.append('phone', values.phone)
            formData.append('message', values.message)
            formData.append('consent1', values.emailOffers.toString())
            formData.append('consent2', values.phoneOffers.toString())
            // youlead params
            formData.append('formName', 'contact')
            formData.append('url', window.location.href)
            formData.append('refereer', window.previousPath)
            if (Cookies.get('ylid')) {
              formData.append('ylid', String(Cookies.get('ylid')))
            }
            if (Cookies.get('ylutm')) {
              formData.append('ylutm', String(Cookies.get('ylutm')))
            }
            if (Cookies.get('ylssid')) {
              formData.append('ylssid', String(Cookies.get('ylssid')))
            }

            const { data } = await axios.post(
              'https://cmsmiasteczko.indigital.guru/mailer/mailer-main.php',
              formData
            )

            emitGTMEvent({
              event: 'form_sent',
              lead_id: data.leadId,
            })

            if (data.yl.returnCode) {
              let returnScript = data.yl.returnCode.replaceAll(
                '<script type="text/javascript">',
                ''
              )
              returnScript = returnScript.replaceAll('<script>', '')
              returnScript = returnScript.replaceAll('</script>', '')
              // eslint-disable-next-line
              eval(returnScript)
            }

            setSubmitting(false)
            setFormInfo({
              text: 'Nasz konsultant skontaktuje się z Tobą.',
              error: false,
            })

            setTimeout(() => {
              setFormInfo({ text: '', error: false })
              resetForm()
            }, 5000)
          } catch (err) {
            setSubmitting(false)
            setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            setTimeout(() => {
              setFormInfo({ text: '', error: false })
            }, 5000)
          }
        })()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <StyledForm onSubmit={handleSubmit}>
          <InputsContainer>
            <Input
              label="Imię i nazwisko"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name}
              touched={touched.name}
            />
            <Input
              label="Adres e-mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              touched={touched.email}
            />
            <Input
              label="Numer telefonu"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone}
              touched={touched.phone}
            />
            <Input
              label="Treść wiadomości"
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.message}
              touched={touched.message}
              type="textarea"
            />
          </InputsContainer>
          <CheckBoxContainer>
            {/* Use <Space> as a break point for "Show more" */}
            <CheckBox
              label="Chcę otrzymywać na mój <b>adres e-mail</b>  od Aurec Home Holding sp. z o.o. <Space> oferty sprzedaży i najmu lokali oraz innych związanych z tym usług oferowanych przez Aurec Home Holding sp. z o.o. oraz inne podmioty z Grupy Kapitałowej Aurec Capital oraz inne podmioty z Grupy Kapitałowej Aurec Capital"
              name="emailOffers"
              value={values.emailOffers}
              error={errors.emailOffers}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <CheckBox
              label="Chcę otrzymywać na mój <b>numer telefonu</b> (połączenia telefoniczne, wiadomości SMS/MMS) od Aurec Home Holding sp. z o.o. <Space> oferty sprzedaży i najmu lokali oraz innych związanych z tym usług oferowanych przez Aurec Home Holding sp. z o.o. oraz inne podmioty z Grupy Kapitałowej Aurec Capital"
              name="phoneOffers"
              value={values.phoneOffers}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextBodySmall>{renderInformation()}</TextBodySmall>
          </CheckBoxContainer>
          <Button TextButton2 noMaxWidth disabled={isSubmitting}>
            <ButtonLine>Wyślij wiadomość</ButtonLine>
          </Button>
        </StyledForm>
      )}
    </Formik>
  )
}
