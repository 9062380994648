import media from 'styles/media'
import styled from 'styled-components'

export const DesktopBreadcrumbs = styled.div`
  display: flex;
  align-items: center;

  ${media.sm.max} {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray05};
  padding: 12px 0;

  a {
    text-decoration: none;
  }

  ${media.sm.max} {
    padding: 8px 24px;
  }
`

export const IconWrapper = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
`

export const BackArrowWraper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;

  &svg,
  path {
    stroke: #727292;
  }
`

export const MobileBreadcrumbs = styled.div`
  display: none;

  ${media.sm.max} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`
