import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H4Bold, TextBody1 } from 'components/atoms/Typography'

export const Wrapper = styled.div`
  padding: 100px 0;

  ${media.md.max} {
    padding: 48px 0;
  }
`

export const StyledContainer = styled(Container)`
  max-width: 1216px;

  ${media.md.max} {
    padding: 0;
  }
`

export const HeadingWrapper = styled.div`
  margin-bottom: 48px;
  display: flex;
  gap: 65px;

  ${media.md.max} {
    flex-direction: column;
    gap: 32px;
    padding: 0 24px;
    margin-bottom: 32px;
  }
`

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  ${media.md.max} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

export const PlacesWrapper = styled.div`
  position: relative;
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray05};
  background-color: ${({ theme }) => theme.colors.white};

  ${H4Bold} {
    margin-bottom: 24px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
  }

  ${media.md.max} {
    padding: 24px 16px;
    margin: 0 24px;

    &:before {
      display: none;
    }

    &:last-child {
      margin-top: -48px;
    }
  }
`

export const PlaceWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(&:last-child) {
    margin-bottom: 12px;
  }

  ${TextBody1} {
    margin-left: 8px;
  }
`

export const IconWrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageWrapper = styled.div`
  display: none;

  ${media.md.max} {
    display: block;
    height: 365px;
    transform: translateY(-24px);
    z-index: -1;
  }
`

export const DesktopDecorWrapper = styled.div`
  flex-shrink: 1;

  svg {
    max-width: 474px;
    width: 100%;
  }

  ${media.lg.max} {
    display: none;
  }
`

export const MobileDecorWrapper = styled.div`
  display: none;

  ${media.lg.max} {
    display: block;
  }
  ${media.xs.max} {
    position: relative;
    left: -24px;
  }
`
