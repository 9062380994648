import styled, { css } from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'
import { Button } from 'components/atoms/Button'

export const Wrapper = styled.div`
  padding: 100px 0;

  ${H1Light} {
    margin: 0 auto 120px;
    max-width: 891px;
  }

  ${media.xl.max} {
    padding: 48px 0;

    ${H1Light} {
      margin: 0 auto 32px;
      padding: 0 24px;
    }
  }

  ${media.sm.max} {
    ${H1Light} {
      text-align: left;
    }
  }
`

export const DirectionsAndMapWrapper = styled.div`
  position: relative;
`

export const DirectionsWrapper = styled.div`
  padding: 32px 64px;
  width: 1136px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  ${media.xl.max} {
    position: static;
    width: 100%;
    transform: none;
  }

  ${media.lg.max} {
    flex-direction: column;
    align-items: stretch;
  }

  ${media.sm.max} {
    padding: 24px;

    ${Button} {
      width: 100%;
      max-width: unset;
      display: flex;
      justify-content: center;
      align-self: stretch;
    }
  }
`

export const Label = styled.label`
  flex-grow: 1;

  ${media.lg.max} {
    margin-bottom: 16px;
  }
`

export const Input = styled.input`
  height: 52px;
  width: 100%;
  border-radius: 32px;
  border: 1px solid ${({ theme }) => theme.colors.gray05};
  padding-left: 15px;
  margin-top: 8px;

  :disabled {
    color: ${({ theme }) => theme.colors.gray10};
    background-color: ${({ theme }) => theme.colors.gray0};
  }
`

export const ArrowIconWrapper = styled.div`
  margin: 0 32px;
  padding: 13px;

  ${media.lg.max} {
    display: none;
  }
`

export const TravelModeButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 32px;

  ${media.lg.max} {
    margin: 0 0 24px;
  }
`

export const ButtonTooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 8px;
  padding: 4px 8px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -25%);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  z-index: 1;
`

export const TravelModeButton = styled.button<{ active: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray05};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.green0};
      border-color: ${({ theme }) => theme.colors.green0};

      svg {
        path {
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }
    `}

  &:hover {
    ${ButtonTooltip} {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`

export const MapWrapper = styled.div`
  height: 580px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.xl.max} {
    height: 480px;
    padding: 0;
  }
`
