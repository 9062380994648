import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const HeroContainer = styled.div`
  margin: 0px auto;
  padding: 0px 32px;

  position: relative;
  display: flex;

  ${media.xxl.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;

  min-height: 542px;
  max-height: 76vh;

  margin: 0px auto;
  margin-top: 64px;

  box-sizing: border-box;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${media.sm.max} {
    margin: 0px;
  }
`

export const HeroContent = styled.div`
  width: 100%;
  max-width: 491px;

  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  position: absolute;
  left: 20px;
  top: 50%;

  transform: translateY(-50%);

  background-color: white;

  ${media.lg.max} {
    top: 70%;
  }
  ${media.sm.max} {
    max-width: 272px;
    height: fit-content;
    top: auto;
    bottom: 48px;
    left: 50%;

    transform: translateX(-50%);

    padding: 24px;
  }
`

export const StyledContainer = styled(Container)`
  height: calc(100% - 64px);

  margin-top: 32px;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`
