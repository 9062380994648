import React from 'react'

import { H1Light } from 'components/atoms/Typography'

import { MapWithDirections } from './MapWithDirections'

import { Wrapper } from './Directions.styles'

type Props = {
  heading: string
  locationCoords: {
    lat: number
    lng: number
  }
}

export const Directions: React.FC<Props> = ({ heading, locationCoords }) => {
  return (
    <Wrapper>
      <H1Light
        dangerouslySetInnerHTML={{ __html: heading }}
        align="center"
        as="h2"
      />
      <MapWithDirections locationCoords={locationCoords} />
    </Wrapper>
  )
}
