import React from 'react'
import { Link } from 'gatsby'

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-green.svg'
import { ReactComponent as BreadcrumbsArrow } from 'assets/icons/breadcrambsBackArrow.svg'

import { TextBody2 } from 'components/atoms/Typography'
import {
  Wrapper,
  IconWrapper,
  BackArrowWraper,
  MobileBreadcrumbs,
  DesktopBreadcrumbs,
} from './Breadcrumbs.styles'

type Crumb = {
  name: string
  to?: string
}

type Props = {
  crumbs: Crumb[]
}

export const Breadcrumbs: React.FC<Props> = ({ crumbs }) => {
  const allCrumbs = [{ name: 'Strona główna', to: '/' }, ...crumbs]

  return (
    <Wrapper>
      <DesktopBreadcrumbs>
        {allCrumbs.map(({ name, to }, index) => {
          const isLast = index === allCrumbs.length - 1

          return (
            <>
              {to ? (
                <Link to={to}>
                  <TextBody2
                    dangerouslySetInnerHTML={{ __html: name }}
                    color={isLast ? 'primary' : 'textPrimary'}
                    align="center"
                  />
                </Link>
              ) : (
                <TextBody2
                  dangerouslySetInnerHTML={{ __html: name }}
                  color={isLast ? 'primary' : 'textPrimary'}
                  align="center"
                />
              )}
              {isLast ? null : (
                <IconWrapper>
                  <ArrowIcon />
                </IconWrapper>
              )}
            </>
          )
        })}
      </DesktopBreadcrumbs>

      <MobileBreadcrumbs>
        <BackArrowWraper>
          <BreadcrumbsArrow />
        </BackArrowWraper>
        <Link to={allCrumbs[allCrumbs.length - 2].to!}>
          <TextBody2>{allCrumbs[allCrumbs.length - 2].name}</TextBody2>
        </Link>
      </MobileBreadcrumbs>
    </Wrapper>
  )
}
