import React from 'react'

import { LazyImage } from 'components/atoms/Image'
import { ImageType } from 'types/image'
import { FormItem } from './Form'

import {
  ContactContainer,
  ContactContent,
  ImageWrapper,
  StyledH1Light,
} from './Contact.styles'

type Props = {
  heading: string
  formTitle: string
  image: ImageType
  initialFormMessage?: string
}

export const Contact: React.FC<Props> = ({
  heading,
  formTitle,
  image,
  initialFormMessage,
}) => {
  return (
    <ContactContainer id="ContactForm">
      <StyledH1Light
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <ContactContent>
        <ImageWrapper>
          <LazyImage
            src={image?.src}
            alt={image?.alt!}
            fill
            objectFit="cover"
          />
        </ImageWrapper>
        <FormItem
          formTitle={formTitle}
          initialFormMessage={initialFormMessage}
        />
      </ContactContent>
    </ContactContainer>
  )
}
