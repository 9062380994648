import React from 'react'
import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'

import { H2Light, TextButton2 } from 'components/atoms/Typography'
import {
  HeroContainer,
  HeroContent,
  ImageWrapper,
  StyledContainer,
} from './LeftContentHero.styles'

type HeroProps = {
  image: ImageType
  lable: string
  text: string
  textTag?: keyof JSX.IntrinsicElements
  headingTag?: keyof JSX.IntrinsicElements
}

export const LeftContentHero = ({
  image,
  lable,
  text,
  textTag,
  headingTag,
}: HeroProps) => {
  return (
    <HeroContainer>
      <ImageWrapper>
        <LazyImage src={image.src} alt={image.alt ?? ''} objectFit="cover" />
      </ImageWrapper>

      <StyledContainer padding={[0, 20]}>
        <HeroContent>
          <TextButton2
            as={textTag}
            dangerouslySetInnerHTML={{ __html: lable }}
          />
          <H2Light as={headingTag} dangerouslySetInnerHTML={{ __html: text }} />
        </HeroContent>
      </StyledContainer>
    </HeroContainer>
  )
}
