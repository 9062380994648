import React from 'react'
import { ImageType } from 'types/image'
import { v4 as uuidv4 } from 'uuid'

import { LazyImage } from 'components/atoms/Image'
import {
  ImageWraper,
  SlideItemContainer,
  SlideItemTextContainer,
  SlideItemWraper,
  SlidesContainer,
  StyledH3Bold,
  StyledTextBody1,
} from './styles'

export type SlidesDataType = Array<{
  positionRight?: boolean
  reversed?: boolean
  image: ImageType
  label: string
  text: string
}>

function ImageSlide({ image }: { image: ImageType }) {
  return (
    <SlideItemContainer>
      <ImageWraper>
        <LazyImage src={image.src} alt={image.alt!} objectFit="cover" />
      </ImageWraper>
    </SlideItemContainer>
  )
}

function TextSlide({
  textHeader,
  textContent,
}: {
  textHeader: string
  textContent: string
}) {
  return (
    <SlideItemTextContainer>
      <StyledH3Bold color="white">{textHeader}</StyledH3Bold>
      <StyledTextBody1 color="white">{textContent}</StyledTextBody1>
    </SlideItemTextContainer>
  )
}

export function generateSlides(slidesData: SlidesDataType) {
  return (
    <SlidesContainer>
      {slidesData.map((item) => (
        <SlideItemWraper
          positonRight={item.positionRight!}
          reversed={!!item.reversed}
          key={uuidv4()}
        >
          <ImageSlide image={item.image} />
          <TextSlide textHeader={item.label} textContent={item.text} />
        </SlideItemWraper>
      ))}
    </SlidesContainer>
  )
}
